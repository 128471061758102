:root {
    --dark-blue: #161f35;
    --light-blue: #01a2ff;
    --dark-yellow: #ffd600;
    --retro-green: #00ff9d;
    --button-green: #2DC30A;
    --button-red: #bb0202;
    --button-blue: #192d5c;
    --hover-blue: #003264;
    --grey: #b9b9b9;
    --bg-color: #0f151d;
    --warning-text-red: #fa2525;
    --header-size: 45px;
    --title-padding: 10px;
}

html, body, #root {
    position: relative;
    width: 100%;
    height: 100%;
    font-size: 13px;
}

body {
    background-color: var(--bg-color);
    color: white;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

* {
    box-sizing: border-box;
}

.flex {
    display: flex;
}

.relative {
    position: relative;
}

.flex-centered {
    display: flex;
    text-align: center;
    justify-content: center;
}

.flex-vertically-centered {
    display: flex;
    flex: 1;
    min-height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.inverted {
    filter: invert(1);
}

.error-msg {
    color: red;
}

.text-center {
    text-align: center;
}

.text-yellow {
    color: var(--dark-yellow);
}

ul {
    list-style: none;
    padding-inline: 0;
    margin: 0;
}

.scrollable {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
}

.clickable {
    cursor: pointer;
}

button {
    vertical-align: center;
    text-align: center;
    cursor: pointer;
    border: 1px solid rgba(255,255,255, 0.25);
    padding: 0.375rem 1.25rem;
    font-size: 14px;
    background: none;
    color: white;
    border-radius: 2px;
}

button:hover:enabled {
    background: var(--light-blue);
    color: var(--dark-blue);
    border-color: var(--light-blue);
}

button.icon-button {
    border: none;
    background: none;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255,255,255,0.65);
}

button.icon-button:active:hover {
    background: none;
    color: white;
}

button[disabled], button[disabled]:hover, button[disabled]:focus {
    cursor: initial;
    background: transparent;
    color: #878787;
}

.smaller-font-btn {
    font-size: 12px;
}

button.small-btn {
    font-size: small;
    display: inline-block;
    padding: 3px 10px;
}

::-webkit-scrollbar {
    width: calc(var(--title-padding) + 5px);
    height: 10px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    border-left: 5px solid transparent;
    background-clip: padding-box;
    background-color: rgba(200,200,200,0.25);
}
.scrollable::-webkit-scrollbar-thumb {
    border-top: 5px solid transparent;
    min-height: 20px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(200,200,200,0.4)
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    white-space: pre;
    max-height: 100%;
    min-height: 50px;
    width: 100%;
    overflow: auto;
}

textarea {
    resize: none;
}

input[type="text"], 
input[type="password"], 
input[type="number"], 
textarea {
    font-family: inherit;
    color: white;
    padding: 4px;
    background: #2c486e7a;
    border: 1px solid rgba(255, 255, 255, 0.25);
}

input[type="text"]:focus-visible, 
input[type="password"]:focus-visible, 
input[type="number"]:focus-visible,
textarea:focus-visible {
    outline: 1px solid var(--light-blue);
}

.input-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
}

.input-container-toggle {
    display: flex;
    align-items: center;
}

.toggle-title {
    width: 100px;
    color: var(--dark-yellow);
}

.toggle-switch {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 30px;
    height: 17px;
    margin-top: 3px;
    margin-left: 2px;
    background-color: rgba(180, 180, 180);
    border-radius: 30px;
    padding: 1px;
    cursor: pointer;
}

.toggle-switch.active {
    background-color: var(--dark-yellow);
}

.toggle-button {
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 50%;
    border: 1px solid lightgrey;
    transition: transform .1s linear;
}

.toggle-switch.active .toggle-button {
    transform: translateX(80%);
}

.capitalize-first-letter:first-letter {
    width: fit-content;
}

.input-title {
    color: var(--dark-yellow);
    margin-bottom: 7px;
}

.input-container input[type="text"],
.input-container input[type="password"],
input[type="number"],
.input-container textarea {
    width: 100%;
    min-height: 35px;
    padding-left: 7px;
    border: 1px solid transparent;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.1);
    font-size: 1.2em;
}

.input-container input[type="text"]:focus-visible,
.input-container input[type="password"]:focus-visible,
.input-container input[type="number"]:focus-visible,
.input-container textarea:focus-visible {
    outline: 1px solid var(--light-blue);
}

input[type="checkbox"] {
    position: absolute;
    margin-left: -5px;
}

input[type="number"] {
    min-height: unset;
}

.check-box{
    margin-top: 3px;
}
.check-box label {
    cursor: pointer;
    margin-left: -4px;
}

.form-dual-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
    margin-top: 10px;
}

.warning-msg {
    width: 100%;
    display: flex;
    align-items: center;
    color: var(--warning-text-red);
    font-weight: bold;
    min-height: 25px;
}

.complete-all-fields-msg {
    margin-top: -35px;
    margin-bottom: 10px;
    height: 20px;
}

.confirm-cancel-btn-cont {
    position: relative;
    display: flex;
    width: 100%;
    gap: 12px;
}
.right {
    justify-content: flex-end;
}
.center {
    justify-content: center;
}
.between {
    justify-content: space-between;
}

button.green-btn:enabled {
    background-color: var(--button-green);
    border-color: var(--button-green);
    color: white;
}

button.red-btn:enabled {
    background-color: var(--button-red);
    border-color: var(--button-red);
    color: white;
}

.confirm-cancel-btn-cont.full button {
    width: 100%;
}

.visible {
    visibility: visible !important;
}
.invisible {
    visibility: hidden !important;
}
.icon-in-btn {
    margin-bottom: -2px;
    margin-right: 5px;
}

table th {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
}

.option-checkbox {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 52px;
    padding: 0 10px;
    margin-bottom: 10px;
    gap: 7px;
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    font-size: 15px;
}

.check-box-icon {
    font-size: 18px;
}

.option-box-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.option-extra-message {
    display: flex;
    align-items: center;
    gap: 3px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
}

.option-extra-message svg {
    margin-top: -2px;
}

.option-extra-message.clear-left {
    margin-left: 0;
}
.option-extra-message.space-top {
    margin-top: 8px;
}

.option-checkbox.active {
    border-color: var(--dark-yellow);
    cursor: auto;
}

.option-checkbox:hover {
    border-color: var(--dark-yellow);
}

.full-game-option {
    display: flex;
    align-items: center;
    margin-top: 7px;
    font-size: 13px;
    gap: 4px;
    cursor: pointer;
}

/* HOVER */
.green-btn:enabled:hover,
.red-btn:enabled:hover {
    filter: brightness(80%);
}
button.red-hover-btn:enabled:hover {
    background-color: var(--button-red);
    border-color: var(--button-red);
    color: white;
}
button.green-hover-btn:enabled:hover {
    background-color: var(--button-green);
    border-color: var(--button-green);
    color: var(--dark-blue);
}
.check-box:hover {
    background: none;
    color: var(--dark-yellow);
}

a {
    cursor: pointer;
    text-decoration: underline;
    color: var(--dark-yellow);
}
a:hover {
    filter: brightness(85%);
}

.full-game-option:hover {
    color: var(--dark-yellow);
}

/* MEDIA QUERY */
@media screen and (max-width: 768px) {
    .smaller-font-btn {
        font-size: 11px;
    }
    .complete-all-fields-msg {
        font-size: 12px;
    }
    .confirm-cancel-btn-cont {
        flex-direction: column;
        gap: 7px;
    }
    .confirm-cancel-btn-cont button {
        width: 100%;
    }
    button {
        font-size: 12px;
        padding: 8px;
    }
    .icon-in-btn {
        font-size: 17px;
    }
    .option-checkbox {
        padding: 0 7px;
        margin-bottom: 7px;
        gap: 10px;
        font-size: 13px;
    }
    .option-extra-message {
        gap: 3px;
        font-size: 11px;
        margin-left: 0;
        margin-top: 3px;
    }
}

@media screen and (max-width: 480px) {
    .input-container input[type="text"],
    .input-container input[type="password"],
    .input-container textarea {
        font-size: 14px;
    }
    .input-title {
        font-weight: 600;
    }
    .smaller-font-btn {
        padding: 0.375rem 0.75rem;;
    }
    .complete-all-fields-msg {
        margin-bottom: 9px;
        font-size: 12px;
    }
}