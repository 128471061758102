.login-container h1 {
    font-size: 3rem;
}
.login-container h1 img {
    height: 160px;
    margin-bottom: 20px;
}
.login-container .error-msg {
    flex-direction: column;
    font-weight: bold;
    font-style: italic;
    color: red;
    text-align: center;
    min-height: 1em;
    width: fit-content;
}
.login-container form {
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    width: 500px;
    max-width: 500px;
}
.login-container form > div {
    display: flex;
    margin-bottom: 1rem;
}
.login-container label,
.login-container button,
.login-container input {
    height: 28px;
}
.login-container button,
.login-container input {
    width: 275px;
    padding-left: 10px;
    padding-right: 10px;
}
.login-container label,
.login-container input {
    padding: 6px;
}
.login-container button,
.login-container .login-separator {
    width: 300px;
}
.login-container label {
    background-color: rgba(0,0,0,0.75);
    margin-right: 0;
}
.login-separator {
    border-top: 1px solid white;
    margin-top: 1rem;
    padding-bottom: 1rem;
}

.login-build-version-text {
    color: grey;
    font-style: italic;
    font-size: small;
}

.login-with-username-link {
    color: grey;
    font-style: italic;
    cursor: pointer;
    font-size: 15px;
}

.login-build-version-text {
    margin-top: 10px;
}

.login-with-username-link:hover {
    color: white;
}