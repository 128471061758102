.game-page-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
}

.game-page-container .game-info {
    min-height: 100px;
    text-align: center;
    font-size: larger;
    width: 100%;
    padding-top: 20px;
}

.game-section-header {
    width: 100%;
    text-transform: uppercase;
    font-weight: 700;
    margin: 6px 0;
    color: var(--dark-yellow);
    border-bottom: 1px solid var(--dark-yellow);
}

.game-asset-listing {
    min-height: 105px;
    max-height: 135px;
    overflow-y: scroll;
}

.game-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.score-and-time {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.game-info-home,
.game-info-away {
    display: flex;
    align-items: center;
    width: 40%;
    height: 100%;
    box-sizing: border-box;
}

.game-info-away {
    justify-content: flex-end;
}

.upload-from-match {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 100%;
    height: 32px;
    margin: -15px 0;
}

.upload-from-match button {
    display: flex;
    align-items: center;
    margin: 15px 0;
    padding: 7px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    color: white;
    font-size: 1.2em;
    cursor: pointer;
}

.upload-from-match-icon {
    margin-right: 7px;
}

.club-name-home,
.club-name-away {
    position: relative;
    display: flex;
    justify-content: flex-start;
    height: 100%;
    width: 70%;
    /* background-color: var(--dark-yellow);
    color: var(--dark-blue); */
    background-color: black;
    color: darkgrey;
    border-right: 50px solid var(--dark-blue);
    border-bottom: 100px solid transparent;
    box-sizing: border-box;
}

.club-name-away {
    border-left: 50px solid var(--dark-blue);
    border-right: 0;
    justify-content: flex-end;
}

.club-name {
    position: absolute;
    display: flex;
    align-items: center;
    text-align: left;
    height: 100px;
    width: 100%;
    margin: 0 10px;
    font-size: 1.0vw;
    font-weight: bold;
    text-transform: uppercase;
}

.club-name-away>.club-name {
    justify-content: right;
    text-align: right;
}

.game-info-date {
    /* background-color: var(--light-blue); */
    background-color: var(--dark-yellow);
    color: var(--dark-blue);
    font-size: 12px;
    padding: 3px 5px;
}

.game-info-score {
    margin: 5px 0;
    font-size: 25px;
    font-weight: bold;
}

.game-info-phase {
    font-size: 12px;
    text-transform: capitalize;
}

.game-info-logo {
    height: 75px;
}

.game-info-live {
    padding: 2px 7px;
    background-color: #E2051B;
    font-size: 12px;
    border-radius: 2px;
}

/* HOVER */
.upload-from-match button:hover {
    background: var(--light-blue);
}